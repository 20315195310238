import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from "./link";

import SoluzioneIcon from "../icons/ico-soluzione.svg";
import EsigenzaIcon from "../icons/ico-esigenza.svg";
import MultiIcon from "../icons/ico-multipiattaforma.svg";
// import Half2Solid from "../icons/half-2-solid.svg";

const BoxCaratteristiche = () => {
  const { caratteristiche } = useStaticQuery(graphql`
    {
      caratteristiche: file(relativePath: { eq: "caratteristiche.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <section className="section">
      <div className="box-caratteristiche">
        <div className="container-lg">
          <div className="row">
            <div className="col-12 col-lg-9">
              <div className="row">
                <div className="col-md-9">
                  <h2 className="section__title">
                    Le caratteristiche esclusive di <strong>Intranet 80.20</strong>
                  </h2>
                  <p>Il prodotto intranet ideale per la tua azienda.</p>
                </div>
              </div>
              <div className="row">
                <div className="col-10 col-md-4">
                  <div className="d-flex flex-column align-items-start h-100">
                    <div className="box-caratteristiche__icon">
                      <SoluzioneIcon className="ico-svg" />
                    </div>
                    <div className="box-caratteristiche__title">Soluzione leader</div>
                    <div className="box-caratteristiche__desc">
                      <p>
                        L'unica soluzione di intranet pensata dal massimo esperto italiano in
                        materia, <strong>Giacomo Mason</strong>, sulla base delle best practices del
                        settore.
                      </p>
                    </div>
                    <Link to="/soluzioni-leader/" className="box-caratteristiche__link mt-auto">
                      Scopri <span>di più</span>
                    </Link>
                  </div>
                </div>
                <div className="col-10 offset-2 col-md-4 offset-md-0">
                  <div className="d-flex flex-column align-items-start">
                    <div className="box-caratteristiche__icon">
                      <EsigenzaIcon className="ico-svg" />
                    </div>
                    <div className="box-caratteristiche__title">Per ogni esigenza</div>
                    <div className="box-caratteristiche__desc">
                      <p>
                        Un prodotto, due versioni: per chi è alla{" "}
                        <strong>prima esperienza intranet</strong> e per chi ne possiede già una ma
                        vuole <strong>evolvere il proprio Digital Workplace</strong>.
                      </p>
                    </div>
                    <Link to="/ogni-esigenza/" className="box-caratteristiche__link mt-auto">
                      Scopri <span>di più</span>
                    </Link>
                  </div>
                </div>
                <div className="col-10 col-md-4">
                  <div className="d-flex flex-column align-items-start">
                    <div className="box-caratteristiche__icon">
                      <MultiIcon className="ico-svg" />
                    </div>
                    <div className="box-caratteristiche__title">Multipiattaforma</div>
                    <div className="box-caratteristiche__desc">
                      <p>
                        La Intranet realizzata sul <strong>back-office nativo</strong> di entrambe
                        le piattaforme di riferimento del mercato: <strong>Liferay</strong> (Java) o{" "}
                        <strong>Microsoft 365</strong>.
                      </p>
                    </div>
                    <Link to="/multipiattaforma/" className="box-caratteristiche__link mt-auto">
                      Scopri <span>di più</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 d-none d-lg-block">
              <GatsbyImage
                image={getImage(caratteristiche)}
                alt="img Le caratteristiche esclusive di Intranet 80.20"
                objectPosition="center center"
                className="box-caratteristiche__img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BoxCaratteristiche;

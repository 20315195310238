import React from "react";
import Slider from "react-slick";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Half8Solid from "../icons/half-8-solid.svg";
import Half8Light from "../icons/half-8-light.svg";
import Half2Solid from "../icons/half-2-solid.svg";
import Decoration from "../icons/decoration.svg";

const settings = {
  arrows: false,
  dots: true,
  infinite: false,
  swipe: true,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        fade: false,
      },
    },
  ],
};

const SliderEsigenze = ({ items }) => {
  return (
    <>
      <section className="section section--esigenze section--dark">
        <div className="container-lg">
          <div className="row">
            <div className="col-12 col-lg-7 offset-lg-5 text-lg-end">
              <h2 className="section__title">
                Cosa può fare <strong>Intranet 80.20</strong> per te?
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 position-relative">
              <Half8Solid className="ico-svg ico-half-8-solid" />
              <Half8Light className="ico-svg ico-half-8-light" />
              <Decoration className="ico-svg ico-decoration" />
              <Slider {...settings} className="slider-esigenze">
                {items.esigenze.map((item, i) => (
                  <div key={i}>
                    <div className="box-esigenza">
                      <div className="box-esigenza__question text-center mb-2">
                        <GatsbyImage
                          image={getImage(item.image)}
                          alt={item.name}
                          className="box-esigenza__img"
                          loading="eager"
                        />
                        {item.name && <div className="box-esigenza__name mb-1">{item.name}</div>}
                        {item.role && <div className="box-esigenza__role mb-3">{item.role}</div>}
                        <div
                          dangerouslySetInnerHTML={{ __html: item.request }}
                          className="box-esigenza__text"
                        ></div>
                      </div>
                      <div className="box-esigenza__solution mb-sm-2">
                        {item.title && <div className="box-esigenza__title">{item.title}</div>}
                        <div
                          dangerouslySetInnerHTML={{ __html: item.text }}
                          className="box-esigenza__text"
                        ></div>
                        <Half2Solid className="ico-svg ico-half-2-solid" />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SliderEsigenze;

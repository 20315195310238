import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import useScroll from "../utility/useScroll";

import Layout from "../components/layout";
import Seo from "../components/seo";
import BoxCaratteristiche from "../components/box-caratteristiche";
import BoxStarter from "../components/box-starter";
import BoxEnterprise from "../components/box-enterprise";
import SliderEsigenze from "../components/slider-esigenze";

import Half8Solid from "../icons/half-8-solid.svg";
import Half8Light from "../icons/half-8-light.svg";
import Half2Solid from "../icons/half-2-solid.svg";
import Half2Light from "../icons/half-2-light.svg";
import Decoration from "../icons/decoration.svg";

const IndexPage = ({ data }) => {
  const { esigenze, hero } = data;

  const scrolled = useScroll(40);

  return (
    <Layout>
      <Seo
        title={`Intranet 80.20 - Connetti la tua azienda`}
        description={`Con Intranet 80.20 migliori la comunicazione interna e aumenti la produttività. Scopri le caratteristiche esclusive della soluzione leader di intranet!`}
      />

      <div className="hero">
        <div className="container-lg">
          <div className="row">
            <div className="col-2 col-sm-3 col-md-4 col-lg-5">
              <div
                className={`hero__animazione h-100${scrolled ? " hero__animazione--scrolled" : ""}`}
              >
                <Half8Solid className="ico-svg ico-half-8-solid" />
                <Half2Solid className="ico-svg ico-half-2-solid" />
              </div>
            </div>
            <div className="col-10 col-sm-9 col-md-8 col-lg-7">
              <h1 className="hero__title">
                Connetti la tua azienda, migliora l'<strong>employee experience</strong>
              </h1>
              <p>
                <strong>Intranet 80.20</strong>, la piattaforma personalizzabile ideata dal massimo
                specialista italiano in materia, <strong>Giacomo Mason</strong>, per coinvolgere gli
                utenti e aumentare la produttività.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1 position-relative">
              <Half8Light className="ico-svg ico-half-8-light" />
              <Half2Light className="ico-svg ico-half-2-light" />
              <Decoration className="ico-svg ico-hero-decoration" />
              <GatsbyImage
                image={getImage(hero)}
                alt="img hero"
                objectPosition="center center"
                className="hero__img hero__img--full"
              />
            </div>
          </div>
        </div>

        <div className="hero__text-scroll hero__text-scroll--hp">
          <div className="text-scroll" aria-hidden="true">
            <div className="text-scroll__wrap">
              <span className="text-uppercase intranet">Intranet</span>
            </div>
            <div className="text-scroll__wrap text-scroll__wrap--repeat">
              <span className="text-uppercase intranet">Intranet</span>
            </div>
          </div>
        </div>
      </div>

      <BoxCaratteristiche />

      <BoxStarter />

      <BoxEnterprise />

      <SliderEsigenze items={esigenze} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    esigenze: esigenzeJson {
      esigenze {
        name
        role
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        request
        title
        text
      }
    }
    hero: file(relativePath: { eq: "hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: BLURRED)
      }
    }
  }
`;

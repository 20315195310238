import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from "./link";

const BoxEnterprise = () => {
  const { enterprise } = useStaticQuery(graphql`
    {
      enterprise: file(relativePath: { eq: "enterprise.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <section className="section">
      <div className="box-piano">
        <div className="box-piano__text-scroll">
          <div className="text-scroll text-scroll--secondary" aria-hidden="true">
            <div className="text-scroll__wrap">
              <span className="text-uppercase enterprise">Enterprise</span>
            </div>
            <div className="text-scroll__wrap text-scroll__wrap--repeat">
              <span className="text-uppercase enterprise">Enterprise</span>
            </div>
          </div>
        </div>
        <div className="container-lg">
          <div className="row">
            <div className="col-12 col-md-5 offset-md-6">
              <GatsbyImage
                image={getImage(enterprise)}
                alt="img Intranet 80.20 Starter"
                objectPosition="center center"
                className="box-piano__img"
              />
            </div>
            <div className="col-12">
              <div className="box-piano__claim box-piano__claim--enterprise d-flex flex-column flex-md-row justify-content-md-between">
                <div className="box-piano__text order-2 order-md-1">
                  La <strong>soluzione personalizzabile</strong>, su misura, che permette di
                  evolvere l'attuale esperienza di intranet aziendale, capace di connettere le
                  persone e creare un ambiente di lavoro di successo.
                </div>
                <div className="box-piano__name order-1 order-md-2 mb-4 mb-md-0 text-end">
                  <strong className="d-block">
                    Intranet{" "}
                    <span className="d-block text-nowrap">
                      80.20 <span className="color-secondary">Enterprise</span>
                    </span>
                  </strong>
                  <Link to="/enterprise/" className="btn-link btn-link--secondary">
                    Piano <span>Enterprise</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BoxEnterprise;

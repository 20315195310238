import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from "./link";

const BoxStarter = () => {
  const { starter } = useStaticQuery(graphql`
    {
      starter: file(relativePath: { eq: "starter.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);
  return (
    <section className="section">
      <div className="container-lg">
        <div className="row">
          <div className="col-11 offset-1 col-lg-7 offset-lg-5 text-lg-end">
            <h2 className="section__title text-end text-lg-start mb-0">
              <strong>Intranet 80.20</strong> è disponibile in due versioni:{" "}
              <strong>Starter</strong> e <strong>Enterprise</strong>
            </h2>
          </div>
        </div>
      </div>
      <div className="box-piano">
        <div className="box-piano__text-scroll">
          <div className="text-scroll text-scroll--primary" aria-hidden="true">
            <div className="text-scroll__wrap">
              <span className="text-uppercase starter">Starter</span>
            </div>
            <div className="text-scroll__wrap text-scroll__wrap--repeat">
              <span className="text-uppercase starter">Starter</span>
            </div>
          </div>
        </div>
        <div className="container-lg">
          <div className="row">
            <div className="col-12 col-md-5">
              <GatsbyImage
                image={getImage(starter)}
                alt="img Intranet 80.20 Starter"
                objectPosition="center center"
                className="box-piano__img"
              />
            </div>
            <div className="col-12 col-md-7 d-flex align-items-end">
              <div className="box-piano__claim box-piano__claim--starter d-flex flex-column flex-md-row align-items-md-end">
                <div className="box-piano__name text-md-end me-3 me-xl-5 mb-4 mb-md-0">
                  <strong className="d-block">
                    Intranet{" "}
                    <span className="d-block text-nowrap">
                      80.20 <span className="color-primary">Starter</span>
                    </span>
                  </strong>
                  <Link to="/starter/" className="btn-link">
                    Piano <span>Starter</span>
                  </Link>
                </div>
                <div className="box-piano__text">
                  Lo strumento nato per rispondere in modo efficace alle esigenze di comunicazione
                  interna per le aziende alla <strong>prima esperienza intranet</strong>.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BoxStarter;
